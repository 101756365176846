import { GraphQLClient } from 'graphql-request';

import { GetGraphQLClientProps } from './client.types';

/**
 * GraphQL client with `x-api-key` header method of authentication
 */
export const graphQLClient = new GraphQLClient(process.env.APPSYNC_ENDPOINT!, {
  headers: { 'x-api-key': `${process.env.APPSYNC_API_KEY!}` },
});

/**
 * To create a client with custom headers and no API KEY, call this
 * function and pass valid `headers`.
 */
export function getGraphQLClient({ headers }: GetGraphQLClientProps = {}) {
  if (headers?.authorization) {
    return new GraphQLClient(process.env.APPSYNC_ENDPOINT!, {
      headers: { authorization: headers.authorization },
    });
  }
  return graphQLClient;
}
