import * as Types from './types';
import { GraphQLClient } from 'graphql-request';
import * as Dom from 'graphql-request/dist/types.dom';
import gql from 'graphql-tag';

export const RateLimitUserDocument = gql`
  mutation RateLimitUser($email: String, $phone: String) {
    rateLimitUser(input: { email: $email, phone: $phone })
  }
`;
export const UpdateUserAddressInfoDocument = gql`
  mutation UpdateUserAddressInfo($id: String!, $addresses: [AddressInput]) {
    updateUser(user: { id: $id, addresses: $addresses }) {
      id
      addresses {
        firstName
        lastName
        phoneNumber
        city
        country
        county
        state
        line1
        line2
        postcode
        defaultShipping
        defaultBilling
      }
    }
  }
`;
export const UpdateUserMarketingPreferencesDocument = gql`
  mutation UpdateUserMarketingPreferences($id: String!, $marketingPreferences: [String!]) {
    updateUser(user: { id: $id, notificationPreferences: $marketingPreferences }) {
      id
      notificationPreferences
    }
  }
`;
export const UpdateUserPreferredClinicDocument = gql`
  mutation UpdateUserPreferredClinic($id: String!, $preferredClinic: String!) {
    updateUser(user: { id: $id, preferredClinic: $preferredClinic }) {
      id
      preferredClinic
    }
  }
`;
export const GetClinicsDocument = gql`
  query GetClinics {
    getClinics {
      clinics {
        address1
        address2
        city
        country
        crmId
        id
        latitude
        longitude
        name
        state
        timeZone
        zip
      }
    }
  }
`;
export const GetStaffByIdsDocument = gql`
  query GetStaffByIds($crmIds: [String!], $ids: [String!]) {
    getStaffByIds(crmIds: $crmIds, ids: $ids) {
      crmBranchId
      crmId
      deleted
      disqualifiedServices
      firstName
      hideFromAppointmentScreen
      hideFromOnlineBookings
      id
      lastName
      staffCategoryId
      staffCategoryName
    }
  }
`;
export const GetUserDocument = gql`
  query GetUser($id: String!) {
    getUser(id: $id) {
      addresses {
        city
        country
        county
        state
        defaultBilling
        defaultShipping
        firstName
        lastName
        line1
        line2
        phoneNumber
        postcode
      }
      birthdate
      birthGender
      crmId
      email
      firstName
      identifyingGender
      lastName
      notificationPreferences
      phoneNumber
      preferredClinic
      signedUpDate
      skinConcern
      skinType
      id
      referredBy {
        crmId
        referralCode
        status
        firstName
        lastName
      }
      referralCode
    }
  }
`;
export const GetUserLoyaltyPointsDocument = gql`
  query GetUserLoyaltyPoints {
    getUserLoyaltyPoints
  }
`;
export const CreateUserDocument = gql`
  mutation CreateUser($email: String!, $phoneNumber: String!, $password: String!) {
    createUser(user: { email: $email, phoneNumber: $phoneNumber, password: $password })
  }
`;
export const ForceResetUserPasswordDocument = gql`
  mutation ForceResetUserPassword($sendCode: Boolean!) {
    forceResetUserPassword(sendCode: $sendCode)
  }
`;
export const SetUserPasswordDocument = gql`
  mutation SetUserPassword($password: String!) {
    setUserPassword(password: $password)
  }
`;
export const CancelUserAppointmentsDocument = gql`
  mutation CancelUserAppointments($appointments: [UserAppointmentCancellationInput!]!) {
    cancelUserAppointments(input: $appointments) {
      appointmentId
      clinicId
      error
      status
    }
  }
`;
export const CreateUserBookingDocument = gql`
  mutation CreateUserBooking($clinicId: String!, $slots: [TimeSlot!]!) {
    createUserBooking(input: { clinicId: $clinicId, slots: $slots }) {
      bookingId
      clientAppointmentSchedules {
        serviceSchedules {
          appointmentId
          clientCourseItemId
          endTime
          serviceId
          staffId
          startTime
          roomId
          machineId
        }
      }
    }
  }
`;
export const UpdateUserBookingDocument = gql`
  mutation UpdateUserBooking($newBooking: BookingUpdateInput!, $oldBooking: BookingUpdateInput!) {
    updateUserBooking(input: { newBooking: $newBooking, oldBooking: $oldBooking }) {
      appointmentDate
      appointmentId
      clientCourseItemId
      endTime
      serviceId
      staffId
      staffRequest
      startTime
      version
    }
  }
`;
export const GetAppointmentTimeslotsDocument = gql`
  query GetAppointmentTimeslots(
    $businessId: String!
    $clinicId: String!
    $fromDateTime: String!
    $toDateTime: String!
    $crmTreatmentIds: [String!]!
    $staffId: String
    $returnAlternativeStaff: Boolean
  ) {
    getAppointmentTimeslots(
      query: {
        businessId: $businessId
        clinicId: $clinicId
        fromDateTime: $fromDateTime
        toDateTime: $toDateTime
        crmTreatmentIds: $crmTreatmentIds
        staffId: $staffId
        returnAlternativeStaff: $returnAlternativeStaff
      }
    ) {
      availableStaff {
        id
        name
      }
      availableTimeslots {
        startTime
        treatmentSchedules {
          appointmentId
          clientCourseItemId
          crmTreatmentId
          endTime
          staffName
          staffId
          staffRequest
          startTime
          roomId
          machineId
        }
      }
    }
  }
`;
export const GetAppointmentsDocument = gql`
  query GetAppointments(
    $clinicId: String!
    $fetchCancelled: Boolean!
    $fromDate: String!
    $toDate: String!
    $page: PaginationInput!
  ) {
    getAppointments(
      query: {
        branchId: $clinicId
        fetchCancelled: $fetchCancelled
        fromDate: $fromDate
        toDate: $toDate
      }
      page: $page
    ) {
      appointments {
        appointmentDate
        appointmentId
        endTime
        serviceId
        staffId
        staffRequest
        version
        startTime
        roomId
        machineId
      }
      page {
        totalPages
        totalElements
        size
        number
      }
    }
  }
`;
export const GetClinicsByTreatmentsDocument = gql`
  query GetClinicsByTreatments($treatmentIds: [String!]!) {
    getClinicsByTreatments(treatmentIds: $treatmentIds) {
      clinics {
        crmId
        id
        name
      }
    }
  }
`;
export const GetUserBookingsHistoryDocument = gql`
  query GetUserBookingsHistory($page: PaginationInput) {
    getUserBookingsHistory(query: { page: $page }) {
      bookingsHistory {
        date
        branchId
        bookings {
          appointmentId
          cancelled
          crmUserTreatmentItemId
          duration
          serviceId
          treatmentCategory
          time
          treatmentTitle
          treatmentId
        }
        bookingId
      }
      page {
        number
        size
        totalElements
        totalPages
      }
    }
  }
`;
export const GetUserTreatmentsDocument = gql`
  query GetUserTreatments($page: PaginationInput, $includeArchived: Boolean) {
    getUserTreatments(query: { page: $page, includeArchived: $includeArchived }) {
      page {
        number
        size
        totalElements
        totalPages
      }
      treatments {
        title
        purchaseDate
        crmTreatmentId
        crmUserTreatmentId
        category
        treatmentId
        userTreatmentItems {
          remainingUnits
          initialUnits
          duration
          crmTreatmentItemId
          crmUserTreatmentId
          crmUserTreatmentItemId
          crmSingleTreatmentId
          singleTreatmentId
        }
      }
    }
  }
`;
export const AddCartItemDocument = gql`
  mutation AddCartItem($cartId: String!, $itemId: String!, $quantity: Int!, $type: CartItemType!) {
    addCartItem(cartId: $cartId, itemId: $itemId, quantity: $quantity, type: $type) {
      createdAt
      id
      items {
        type
        quantity
        itemId
        totalCost
        itemCost
        addedAt
        updatedAt
      }
      deliveryCost
      updatedAt
      totalCost
      subtotalCost
    }
  }
`;
export const CreateOrderDocument = gql`
  mutation CreateOrder(
    $payments: [PurchasePayment!]!
    $channelSource: ChannelSourceInput!
    $billingAddress: AddressInput
    $shippingAddress: AddressInput
  ) {
    createOrder(
      input: {
        payments: $payments
        channelSource: $channelSource
        billingAddress: $billingAddress
        shippingAddress: $shippingAddress
      }
    ) {
      orderId
    }
  }
`;
export const CreatePurchaseTransactionSyncDocument = gql`
  mutation CreatePurchaseTransactionSync(
    $payments: [PurchasePayment!]!
    $channelSource: ChannelSourceInput!
    $billingAddress: AddressInput
    $shippingAddress: AddressInput
  ) {
    createPurchaseTransactionSync(
      input: {
        payments: $payments
        channelSource: $channelSource
        billingAddress: $billingAddress
        shippingAddress: $shippingAddress
      }
    ) {
      id
      status
    }
  }
`;
export const EmptyCartDocument = gql`
  mutation EmptyCart($cartId: String!) {
    emptyCart(cartId: $cartId) {
      id
      items {
        type
        quantity
        itemId
        itemCost
        totalCost
        addedAt
        updatedAt
      }
      createdAt
      deliveryCost
      updatedAt
      totalCost
      subtotalCost
    }
  }
`;
export const RefreshCartAfterLoginDocument = gql`
  mutation RefreshCartAfterLogin($cartId: String!) {
    refreshCartAfterLogin(cartId: $cartId) {
      id
      items {
        type
        quantity
        itemId
        itemCost
        totalCost
        addedAt
        updatedAt
      }
      deliveryCost
      createdAt
      updatedAt
      totalCost
      subtotalCost
    }
  }
`;
export const TriggerTransactionDocument = gql`
  mutation TriggerTransaction($orderId: String!) {
    triggerTransaction(orderId: $orderId) {
      transactionId
    }
  }
`;
export const GetCartDocument = gql`
  query GetCart($id: String!, $forceUpdate: Boolean) {
    getCart(query: $id, forceUpdate: $forceUpdate) {
      id
      items {
        type
        quantity
        itemId
        itemCost
        totalCost
        addedAt
        updatedAt
        priceChanged
      }
      deliveryCost
      createdAt
      updatedAt
      totalCost
      subtotalCost
    }
  }
`;
export const GetCartEnrichedDocument = gql`
  query GetCartEnriched($id: String!) {
    getCartEnriched(cartId: $id) {
      createdAt
      deliveryCost
      grossSubtotalCost
      grossTotalCost
      id
      items {
        addedAt
        grossItemCost
        id
        itemCost
        itemDiscount
        itemId
        netItemCost
        priceChanged
        quantity
        totalCost
        type
        updatedAt
      }
      netSubtotalCost
      netTotalCost
      subtotalCost
      totalCost
      totalDiscount
      updatedAt
    }
  }
`;
export const GetOrderByIdDocument = gql`
  query GetOrderById($orderId: String!) {
    getOrderById(orderId: $orderId) {
      userId
      mergedFromUserId
      total
      taxes
      paymentStatus
      paymentStatusLastChange
      cardPayment
      loyaltyPointsPayment
      orderId
      publicId
      items {
        itemCost
        itemId
        name
        quantity
        totalCost
        type
        taxRate
        grossItemCost
        netItemCost
        itemDiscount
        totalDiscount
      }
      fulfillmentStatus
      deliveryCost
      date
      billingDetails {
        postcode
        phoneNumber
        line2
        line1
        lastName
        firstName
        defaultShipping
        defaultBilling
        country
        county
        state
        city
      }
      voucherPayment {
        amount
        serialNumber
      }
      shippingDetails {
        postcode
        phoneNumber
        line2
        line1
        lastName
        firstName
        defaultShipping
        defaultBilling
        country
        county
        state
        city
      }
      vouchers {
        expiryDate
        remainingBalance
        serialNumber
      }
      isDeleted
    }
  }
`;
export const GetOrderPaymentStatusDocument = gql`
  query GetOrderPaymentStatus($orderId: String!) {
    getOrderById(orderId: $orderId) {
      paymentStatus
    }
  }
`;
export const GetUserSavedPaymentMethodsDocument = gql`
  query GetUserSavedPaymentMethods {
    getUserSavedPaymentMethods {
      id
      card {
        brand
        last4
        exp_year
        exp_month
      }
    }
  }
`;
export const GetVoucherBalanceBySerialNumberDocument = gql`
  query GetVoucherBalanceBySerialNumber($serialNumber: String!) {
    getVoucherBalanceBySerialNumber(serialNumber: $serialNumber)
  }
`;
export const DeletePaymentMethodDocument = gql`
  mutation DeletePaymentMethod($paymentMethodId: String!) {
    deletePaymentMethod(paymentMethodId: $paymentMethodId) {
      error
      status
    }
  }
`;
export const SendVouchersDocument = gql`
  mutation SendVouchers($orderId: String!) {
    sendVouchers(orderId: $orderId)
  }
`;
export const UpdateUserPersonalInfoDocument = gql`
  mutation UpdateUserPersonalInfo(
    $id: String!
    $firstName: String!
    $lastName: String!
    $birthGender: String!
    $identifyingGender: String!
    $birthdate: String!
    $skinConcern: String!
    $skinType: String!
    $preferredClinic: String!
  ) {
    updateUser(
      user: {
        id: $id
        firstName: $firstName
        lastName: $lastName
        birthGender: $birthGender
        identifyingGender: $identifyingGender
        birthdate: $birthdate
        skinConcern: $skinConcern
        skinType: $skinType
        preferredClinic: $preferredClinic
      }
    ) {
      id
      crmId
      email
      phoneNumber
      firstName
      lastName
      birthGender
      identifyingGender
      birthdate
      skinConcern
      skinType
      preferredClinic
      signedUpDate
    }
  }
`;
export const GetLookupValuesDocument = gql`
  query GetLookupValues {
    getLookupValues {
      name
      values
    }
  }
`;
export const GetOrdersDocument = gql`
  query GetOrders($lastOrderId: String, $limit: Int) {
    getOrders(lastOrderId: $lastOrderId, limit: $limit) {
      lastOrderId
      orders {
        userId
        mergedFromUserId
        total
        taxes
        paymentStatus
        paymentStatusLastChange
        cardPayment
        loyaltyPointsPayment
        orderId
        publicId
        items {
          itemCost
          itemId
          name
          quantity
          totalCost
          type
          taxRate
          grossItemCost
          netItemCost
          itemDiscount
          totalDiscount
        }
        fulfillmentStatus
        deliveryCost
        date
        billingDetails {
          postcode
          phoneNumber
          line2
          line1
          lastName
          firstName
          defaultShipping
          defaultBilling
          country
          county
          state
          city
        }
        voucherPayment {
          amount
          serialNumber
        }
        shippingDetails {
          postcode
          phoneNumber
          line2
          line1
          lastName
          firstName
          defaultShipping
          defaultBilling
          country
          county
          state
          city
        }
        vouchers {
          expiryDate
          remainingBalance
          serialNumber
        }
      }
    }
  }
`;
export const GetProductBySlugDocument = gql`
  query GetProductBySlug($query: String!) {
    getProductBySlug(query: $query) {
      id
      crmId
      name
      images {
        original
        thumbnail
      }
      sizeUnit
      sizeValue
      comparePrice
      price
      priceWithTax
      priceWithoutTax
      description
      outOfStock
      status
    }
  }
`;
export const GetProductGroupsDocument = gql`
  query GetProductGroups($component: String!) {
    getProductGroups(query: $component) {
      id
      name
      image
      description
      order
      component
      searchFilter {
        name
        brandName
        productType
        skinType
        skinConcern
        productRange
      }
    }
  }
`;
export const GetProductsDocument = gql`
  query GetProducts($ids: [String!]!) {
    getProducts(query: $ids) {
      id
      name
      brandName
      crmId
      images {
        original
        thumbnail
      }
      price
      priceWithTax
      priceWithoutTax
      slug
      outOfStock
    }
  }
`;
export const GetVouchersDocument = gql`
  query GetVouchers {
    getVouchers {
      id
      name
      slug
      status
      price
      brandName
    }
  }
`;
export const SearchProductsDocument = gql`
  query SearchProducts($filters: ProductSearchInput!) {
    searchProducts(query: $filters) {
      products {
        id
        crmId
        name
        price
        priceWithTax
        priceWithoutTax
        slug
        comparePrice
        brandName
        status
        images {
          thumbnail
          original
        }
        outOfStock
      }
      availableFilters {
        name
        values
      }
    }
  }
`;
export const GetUserByReferralCodeDocument = gql`
  query GetUserByReferralCode($referralCode: String!) {
    getUserByReferralCode(referralCode: $referralCode) {
      firstName
      lastName
    }
  }
`;
export const CreatePaymentIntentDocument = gql`
  mutation CreatePaymentIntent(
    $intentType: PaymentIntentType!
    $values: CreatePaymentIntentValuesInput!
  ) {
    createPaymentIntent(intentType: $intentType, values: $values) {
      status
      intent
    }
  }
`;
export const DeletePaymentIntentDocument = gql`
  mutation DeletePaymentIntent($intentId: String!, $intentType: PaymentIntentType!) {
    deletePaymentIntent(intentId: $intentId, intentType: $intentType) {
      status
      intent
    }
  }
`;
export const GetPaymentIntentDocument = gql`
  query GetPaymentIntent($intentId: String!, $intentType: PaymentIntentType!) {
    getPaymentIntent(intentId: $intentId, intentType: $intentType)
  }
`;
export const UpdatePaymentIntentDocument = gql`
  mutation UpdatePaymentIntent(
    $intentId: String!
    $intentType: PaymentIntentType!
    $values: UpdatePaymentIntentValuesInput!
  ) {
    updatePaymentIntent(intentId: $intentId, intentType: $intentType, values: $values) {
      status
      intent
    }
  }
`;
export const GetPaymentServiceUserDocument = gql`
  query GetPaymentServiceUser {
    getPaymentServiceUser {
      id
    }
  }
`;
export const GetTreatmentDocument = gql`
  query GetTreatment($input: GetTreatmentInput!) {
    getTreatment(input: $input) {
      bookOnPurchase
      buyNowPayLater {
        enabled
        installments
        surchargePercentage
      }
      category
      collectionSlug
      comparePrice
      crmId
      description
      id
      image {
        original
        thumbnail
      }
      numberOfSessions
      numberOfCycles
      onSale
      payInClinic
      price
      priceWithTax
      priceWithoutTax
      slug
      nuaSlug
      title
      duration
      bookAsTreatmentId
      treatmentGroup
      treatmentType
    }
  }
`;
export const GetTreatmentCollectionBySlugDocument = gql`
  query GetTreatmentCollectionBySlug($slug: String!) {
    getTreatmentCollectionBySlug(query: $slug) {
      description
      id
      image {
        original
        thumbnail
      }
      name
      groupSections
      catalogCover
      sections {
        order
        subsections {
          title
          hideTitle
          collapse
          collapseByAttribute
          order
          readOnly
          items {
            bookOnPurchase
            bookAsTreatmentId
            buyNowPayLater {
              enabled
              installments
              surchargePercentage
            }
            category
            collectionSlug
            comparePrice
            crmId
            description
            id
            image {
              original
              thumbnail
            }
            numberOfSessions
            numberOfCycles
            onSale
            order
            payInClinic
            price
            priceWithTax
            priceWithoutTax
            slug
            title
          }
        }
        title
      }
      slug
      title
      treatmentGroup
    }
  }
`;
export const GetTreatmentCollectionsDocument = gql`
  query GetTreatmentCollections {
    getTreatmentCollections {
      description
      id
      image {
        original
        thumbnail
      }
      name
      order
      slug
      title
      treatmentGroup
      groupSections
      catalogCover
    }
  }
`;
export const GetTreatmentsDocument = gql`
  query GetTreatments($ids: [String!], $crmIds: [String!], $courseCrmIds: [String!]) {
    getTreatments(ids: $ids, serviceCrmIds: $crmIds, courseCrmIds: $courseCrmIds) {
      bookOnPurchase
      buyNowPayLater {
        enabled
        installments
        surchargePercentage
      }
      category
      comparePrice
      collectionSlug
      crmId
      description
      id
      image {
        original
        thumbnail
      }
      numberOfSessions
      onSale
      payInClinic
      price
      priceWithTax
      priceWithoutTax
      slug
      nuaSlug
      title
      duration
      bookAsTreatmentId
      treatmentGroup
      treatmentType
    }
  }
`;

export type SdkFunctionWrapper = <T>(
  action: (requestHeaders?: Record<string, string>) => Promise<T>,
  operationName: string
) => Promise<T>;

const defaultWrapper: SdkFunctionWrapper = (action, _operationName) => action();

export function getSdk(client: GraphQLClient, withWrapper: SdkFunctionWrapper = defaultWrapper) {
  return {
    RateLimitUser(
      variables?: Types.RateLimitUserMutationVariables,
      requestHeaders?: Dom.RequestInit['headers']
    ): Promise<Types.RateLimitUserMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<Types.RateLimitUserMutation>(RateLimitUserDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'RateLimitUser'
      );
    },
    UpdateUserAddressInfo(
      variables: Types.UpdateUserAddressInfoMutationVariables,
      requestHeaders?: Dom.RequestInit['headers']
    ): Promise<Types.UpdateUserAddressInfoMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<Types.UpdateUserAddressInfoMutation>(
            UpdateUserAddressInfoDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        'UpdateUserAddressInfo'
      );
    },
    UpdateUserMarketingPreferences(
      variables: Types.UpdateUserMarketingPreferencesMutationVariables,
      requestHeaders?: Dom.RequestInit['headers']
    ): Promise<Types.UpdateUserMarketingPreferencesMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<Types.UpdateUserMarketingPreferencesMutation>(
            UpdateUserMarketingPreferencesDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        'UpdateUserMarketingPreferences'
      );
    },
    UpdateUserPreferredClinic(
      variables: Types.UpdateUserPreferredClinicMutationVariables,
      requestHeaders?: Dom.RequestInit['headers']
    ): Promise<Types.UpdateUserPreferredClinicMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<Types.UpdateUserPreferredClinicMutation>(
            UpdateUserPreferredClinicDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        'UpdateUserPreferredClinic'
      );
    },
    GetClinics(
      variables?: Types.GetClinicsQueryVariables,
      requestHeaders?: Dom.RequestInit['headers']
    ): Promise<Types.GetClinicsQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<Types.GetClinicsQuery>(GetClinicsDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'GetClinics'
      );
    },
    GetStaffByIds(
      variables?: Types.GetStaffByIdsQueryVariables,
      requestHeaders?: Dom.RequestInit['headers']
    ): Promise<Types.GetStaffByIdsQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<Types.GetStaffByIdsQuery>(GetStaffByIdsDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'GetStaffByIds'
      );
    },
    GetUser(
      variables: Types.GetUserQueryVariables,
      requestHeaders?: Dom.RequestInit['headers']
    ): Promise<Types.GetUserQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<Types.GetUserQuery>(GetUserDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'GetUser'
      );
    },
    GetUserLoyaltyPoints(
      variables?: Types.GetUserLoyaltyPointsQueryVariables,
      requestHeaders?: Dom.RequestInit['headers']
    ): Promise<Types.GetUserLoyaltyPointsQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<Types.GetUserLoyaltyPointsQuery>(GetUserLoyaltyPointsDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'GetUserLoyaltyPoints'
      );
    },
    CreateUser(
      variables: Types.CreateUserMutationVariables,
      requestHeaders?: Dom.RequestInit['headers']
    ): Promise<Types.CreateUserMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<Types.CreateUserMutation>(CreateUserDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'CreateUser'
      );
    },
    ForceResetUserPassword(
      variables: Types.ForceResetUserPasswordMutationVariables,
      requestHeaders?: Dom.RequestInit['headers']
    ): Promise<Types.ForceResetUserPasswordMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<Types.ForceResetUserPasswordMutation>(
            ForceResetUserPasswordDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        'ForceResetUserPassword'
      );
    },
    SetUserPassword(
      variables: Types.SetUserPasswordMutationVariables,
      requestHeaders?: Dom.RequestInit['headers']
    ): Promise<Types.SetUserPasswordMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<Types.SetUserPasswordMutation>(SetUserPasswordDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'SetUserPassword'
      );
    },
    CancelUserAppointments(
      variables: Types.CancelUserAppointmentsMutationVariables,
      requestHeaders?: Dom.RequestInit['headers']
    ): Promise<Types.CancelUserAppointmentsMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<Types.CancelUserAppointmentsMutation>(
            CancelUserAppointmentsDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        'CancelUserAppointments'
      );
    },
    CreateUserBooking(
      variables: Types.CreateUserBookingMutationVariables,
      requestHeaders?: Dom.RequestInit['headers']
    ): Promise<Types.CreateUserBookingMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<Types.CreateUserBookingMutation>(CreateUserBookingDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'CreateUserBooking'
      );
    },
    UpdateUserBooking(
      variables: Types.UpdateUserBookingMutationVariables,
      requestHeaders?: Dom.RequestInit['headers']
    ): Promise<Types.UpdateUserBookingMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<Types.UpdateUserBookingMutation>(UpdateUserBookingDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'UpdateUserBooking'
      );
    },
    GetAppointmentTimeslots(
      variables: Types.GetAppointmentTimeslotsQueryVariables,
      requestHeaders?: Dom.RequestInit['headers']
    ): Promise<Types.GetAppointmentTimeslotsQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<Types.GetAppointmentTimeslotsQuery>(
            GetAppointmentTimeslotsDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        'GetAppointmentTimeslots'
      );
    },
    GetAppointments(
      variables: Types.GetAppointmentsQueryVariables,
      requestHeaders?: Dom.RequestInit['headers']
    ): Promise<Types.GetAppointmentsQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<Types.GetAppointmentsQuery>(GetAppointmentsDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'GetAppointments'
      );
    },
    GetClinicsByTreatments(
      variables: Types.GetClinicsByTreatmentsQueryVariables,
      requestHeaders?: Dom.RequestInit['headers']
    ): Promise<Types.GetClinicsByTreatmentsQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<Types.GetClinicsByTreatmentsQuery>(
            GetClinicsByTreatmentsDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        'GetClinicsByTreatments'
      );
    },
    GetUserBookingsHistory(
      variables?: Types.GetUserBookingsHistoryQueryVariables,
      requestHeaders?: Dom.RequestInit['headers']
    ): Promise<Types.GetUserBookingsHistoryQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<Types.GetUserBookingsHistoryQuery>(
            GetUserBookingsHistoryDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        'GetUserBookingsHistory'
      );
    },
    GetUserTreatments(
      variables?: Types.GetUserTreatmentsQueryVariables,
      requestHeaders?: Dom.RequestInit['headers']
    ): Promise<Types.GetUserTreatmentsQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<Types.GetUserTreatmentsQuery>(GetUserTreatmentsDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'GetUserTreatments'
      );
    },
    AddCartItem(
      variables: Types.AddCartItemMutationVariables,
      requestHeaders?: Dom.RequestInit['headers']
    ): Promise<Types.AddCartItemMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<Types.AddCartItemMutation>(AddCartItemDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'AddCartItem'
      );
    },
    CreateOrder(
      variables: Types.CreateOrderMutationVariables,
      requestHeaders?: Dom.RequestInit['headers']
    ): Promise<Types.CreateOrderMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<Types.CreateOrderMutation>(CreateOrderDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'CreateOrder'
      );
    },
    CreatePurchaseTransactionSync(
      variables: Types.CreatePurchaseTransactionSyncMutationVariables,
      requestHeaders?: Dom.RequestInit['headers']
    ): Promise<Types.CreatePurchaseTransactionSyncMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<Types.CreatePurchaseTransactionSyncMutation>(
            CreatePurchaseTransactionSyncDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        'CreatePurchaseTransactionSync'
      );
    },
    EmptyCart(
      variables: Types.EmptyCartMutationVariables,
      requestHeaders?: Dom.RequestInit['headers']
    ): Promise<Types.EmptyCartMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<Types.EmptyCartMutation>(EmptyCartDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'EmptyCart'
      );
    },
    RefreshCartAfterLogin(
      variables: Types.RefreshCartAfterLoginMutationVariables,
      requestHeaders?: Dom.RequestInit['headers']
    ): Promise<Types.RefreshCartAfterLoginMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<Types.RefreshCartAfterLoginMutation>(
            RefreshCartAfterLoginDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        'RefreshCartAfterLogin'
      );
    },
    TriggerTransaction(
      variables: Types.TriggerTransactionMutationVariables,
      requestHeaders?: Dom.RequestInit['headers']
    ): Promise<Types.TriggerTransactionMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<Types.TriggerTransactionMutation>(TriggerTransactionDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'TriggerTransaction'
      );
    },
    GetCart(
      variables: Types.GetCartQueryVariables,
      requestHeaders?: Dom.RequestInit['headers']
    ): Promise<Types.GetCartQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<Types.GetCartQuery>(GetCartDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'GetCart'
      );
    },
    GetCartEnriched(
      variables: Types.GetCartEnrichedQueryVariables,
      requestHeaders?: Dom.RequestInit['headers']
    ): Promise<Types.GetCartEnrichedQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<Types.GetCartEnrichedQuery>(GetCartEnrichedDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'GetCartEnriched'
      );
    },
    GetOrderById(
      variables: Types.GetOrderByIdQueryVariables,
      requestHeaders?: Dom.RequestInit['headers']
    ): Promise<Types.GetOrderByIdQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<Types.GetOrderByIdQuery>(GetOrderByIdDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'GetOrderById'
      );
    },
    GetOrderPaymentStatus(
      variables: Types.GetOrderPaymentStatusQueryVariables,
      requestHeaders?: Dom.RequestInit['headers']
    ): Promise<Types.GetOrderPaymentStatusQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<Types.GetOrderPaymentStatusQuery>(
            GetOrderPaymentStatusDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        'GetOrderPaymentStatus'
      );
    },
    GetUserSavedPaymentMethods(
      variables?: Types.GetUserSavedPaymentMethodsQueryVariables,
      requestHeaders?: Dom.RequestInit['headers']
    ): Promise<Types.GetUserSavedPaymentMethodsQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<Types.GetUserSavedPaymentMethodsQuery>(
            GetUserSavedPaymentMethodsDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        'GetUserSavedPaymentMethods'
      );
    },
    GetVoucherBalanceBySerialNumber(
      variables: Types.GetVoucherBalanceBySerialNumberQueryVariables,
      requestHeaders?: Dom.RequestInit['headers']
    ): Promise<Types.GetVoucherBalanceBySerialNumberQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<Types.GetVoucherBalanceBySerialNumberQuery>(
            GetVoucherBalanceBySerialNumberDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        'GetVoucherBalanceBySerialNumber'
      );
    },
    DeletePaymentMethod(
      variables: Types.DeletePaymentMethodMutationVariables,
      requestHeaders?: Dom.RequestInit['headers']
    ): Promise<Types.DeletePaymentMethodMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<Types.DeletePaymentMethodMutation>(
            DeletePaymentMethodDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        'DeletePaymentMethod'
      );
    },
    SendVouchers(
      variables: Types.SendVouchersMutationVariables,
      requestHeaders?: Dom.RequestInit['headers']
    ): Promise<Types.SendVouchersMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<Types.SendVouchersMutation>(SendVouchersDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'SendVouchers'
      );
    },
    UpdateUserPersonalInfo(
      variables: Types.UpdateUserPersonalInfoMutationVariables,
      requestHeaders?: Dom.RequestInit['headers']
    ): Promise<Types.UpdateUserPersonalInfoMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<Types.UpdateUserPersonalInfoMutation>(
            UpdateUserPersonalInfoDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        'UpdateUserPersonalInfo'
      );
    },
    GetLookupValues(
      variables?: Types.GetLookupValuesQueryVariables,
      requestHeaders?: Dom.RequestInit['headers']
    ): Promise<Types.GetLookupValuesQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<Types.GetLookupValuesQuery>(GetLookupValuesDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'GetLookupValues'
      );
    },
    GetOrders(
      variables?: Types.GetOrdersQueryVariables,
      requestHeaders?: Dom.RequestInit['headers']
    ): Promise<Types.GetOrdersQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<Types.GetOrdersQuery>(GetOrdersDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'GetOrders'
      );
    },
    GetProductBySlug(
      variables: Types.GetProductBySlugQueryVariables,
      requestHeaders?: Dom.RequestInit['headers']
    ): Promise<Types.GetProductBySlugQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<Types.GetProductBySlugQuery>(GetProductBySlugDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'GetProductBySlug'
      );
    },
    GetProductGroups(
      variables: Types.GetProductGroupsQueryVariables,
      requestHeaders?: Dom.RequestInit['headers']
    ): Promise<Types.GetProductGroupsQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<Types.GetProductGroupsQuery>(GetProductGroupsDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'GetProductGroups'
      );
    },
    GetProducts(
      variables: Types.GetProductsQueryVariables,
      requestHeaders?: Dom.RequestInit['headers']
    ): Promise<Types.GetProductsQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<Types.GetProductsQuery>(GetProductsDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'GetProducts'
      );
    },
    GetVouchers(
      variables?: Types.GetVouchersQueryVariables,
      requestHeaders?: Dom.RequestInit['headers']
    ): Promise<Types.GetVouchersQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<Types.GetVouchersQuery>(GetVouchersDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'GetVouchers'
      );
    },
    SearchProducts(
      variables: Types.SearchProductsQueryVariables,
      requestHeaders?: Dom.RequestInit['headers']
    ): Promise<Types.SearchProductsQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<Types.SearchProductsQuery>(SearchProductsDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'SearchProducts'
      );
    },
    GetUserByReferralCode(
      variables: Types.GetUserByReferralCodeQueryVariables,
      requestHeaders?: Dom.RequestInit['headers']
    ): Promise<Types.GetUserByReferralCodeQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<Types.GetUserByReferralCodeQuery>(
            GetUserByReferralCodeDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        'GetUserByReferralCode'
      );
    },
    CreatePaymentIntent(
      variables: Types.CreatePaymentIntentMutationVariables,
      requestHeaders?: Dom.RequestInit['headers']
    ): Promise<Types.CreatePaymentIntentMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<Types.CreatePaymentIntentMutation>(
            CreatePaymentIntentDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        'CreatePaymentIntent'
      );
    },
    DeletePaymentIntent(
      variables: Types.DeletePaymentIntentMutationVariables,
      requestHeaders?: Dom.RequestInit['headers']
    ): Promise<Types.DeletePaymentIntentMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<Types.DeletePaymentIntentMutation>(
            DeletePaymentIntentDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        'DeletePaymentIntent'
      );
    },
    GetPaymentIntent(
      variables: Types.GetPaymentIntentQueryVariables,
      requestHeaders?: Dom.RequestInit['headers']
    ): Promise<Types.GetPaymentIntentQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<Types.GetPaymentIntentQuery>(GetPaymentIntentDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'GetPaymentIntent'
      );
    },
    UpdatePaymentIntent(
      variables: Types.UpdatePaymentIntentMutationVariables,
      requestHeaders?: Dom.RequestInit['headers']
    ): Promise<Types.UpdatePaymentIntentMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<Types.UpdatePaymentIntentMutation>(
            UpdatePaymentIntentDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        'UpdatePaymentIntent'
      );
    },
    GetPaymentServiceUser(
      variables?: Types.GetPaymentServiceUserQueryVariables,
      requestHeaders?: Dom.RequestInit['headers']
    ): Promise<Types.GetPaymentServiceUserQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<Types.GetPaymentServiceUserQuery>(
            GetPaymentServiceUserDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        'GetPaymentServiceUser'
      );
    },
    GetTreatment(
      variables: Types.GetTreatmentQueryVariables,
      requestHeaders?: Dom.RequestInit['headers']
    ): Promise<Types.GetTreatmentQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<Types.GetTreatmentQuery>(GetTreatmentDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'GetTreatment'
      );
    },
    GetTreatmentCollectionBySlug(
      variables: Types.GetTreatmentCollectionBySlugQueryVariables,
      requestHeaders?: Dom.RequestInit['headers']
    ): Promise<Types.GetTreatmentCollectionBySlugQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<Types.GetTreatmentCollectionBySlugQuery>(
            GetTreatmentCollectionBySlugDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        'GetTreatmentCollectionBySlug'
      );
    },
    GetTreatmentCollections(
      variables?: Types.GetTreatmentCollectionsQueryVariables,
      requestHeaders?: Dom.RequestInit['headers']
    ): Promise<Types.GetTreatmentCollectionsQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<Types.GetTreatmentCollectionsQuery>(
            GetTreatmentCollectionsDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        'GetTreatmentCollections'
      );
    },
    GetTreatments(
      variables?: Types.GetTreatmentsQueryVariables,
      requestHeaders?: Dom.RequestInit['headers']
    ): Promise<Types.GetTreatmentsQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<Types.GetTreatmentsQuery>(GetTreatmentsDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'GetTreatments'
      );
    },
  };
}
export type Sdk = ReturnType<typeof getSdk>;
